<template>
  <div>
    <section class="un-details-blog d-lg-none d-block">
      <div class="head">
        <div class="cover_img">
          <picture>
            <source
              :srcset="
                !adsDetail.photo?.startsWith('/images')
                  ? 'https://api-dapps.dinowallet.org/images/ads/' +
                    adsDetail?.photo
                  : adsDetail?.photo
              "
              class="img-fluid"
              width="100%"
              height="180px"
              style="width: 100% !important; height: 180px !important"
              type="image/webp"
            />
            <img
              :src="
                !adsDetail.photo?.startsWith('/images')
                  ? 'https://api-dapps.dinowallet.org/images/ads/' +
                    adsDetail?.photo
                  : adsDetail?.photo
              "
              class="img-fluid"
              width="100%"
              height="180px"
              style="width: 100% !important; height: 180px !important"
              alt=""
            />
          </picture>
          <div
            class="
              btn-xs-size
              bg-dark
              text-white
              rounded-pill
              position-absolute
              start-0
              top-0
              m-3
            "
          >
            {{ adsDetail.name }}
          </div>
        </div>
      </div>
      <div class="body">
        <div class="title-blog">
          <div class="others">
            <div class="time">
              <i class="ri-time-line"></i>
              <span>{{ adsDetail.created_date }}</span>
            </div>
          </div>
        </div>
        <div class="description">
          <h2>{{ adsDetail.title }}</h2>
          <p>
            {{ adsDetail.description }}
          </p>
        </div>
        <div class="bok-next-prev margin-t-40 margin-b-40">
          <a :href="adsDetail.link" class="next">
            <div class="icon">
              <i class="ri-arrow-right-line"></i>
            </div>
            <span class="text-success">Go To Link</span>
          </a>
        </div>
      </div>
    </section>

    <section class="bg-light d-lg-block d-none">
      <div
        class="container py-4"
        style="padding-left: 20px; padding-right: 20px"
      >
        <div class="bg-white p-4 rounded-10 un-details-blog">
          <div class="head mt-3">
            <div class="cover_img">
              <picture>
                <source :srcset="adsDetail.photo" type="image/webp" />
                <img :src="adsDetail.photo" class="big" alt="" />
              </picture>
              <div
                class="
                  btn-xs-size
                  bg-dark
                  text-white
                  rounded-pill
                  position-absolute
                  start-0
                  top-0
                  m-3
                "
              >
                {{ adsDetail.name }}
              </div>
            </div>
          </div>
          <div class="body">
            <div class="title-blog">
              <div class="others">
                <div class="time">
                  <i class="ri-time-line"></i>
                  <span>{{ adsDetail.created_date }}</span>
                </div>
              </div>
            </div>
            <div class="description">
              <h2>{{ adsDetail.title }}</h2>
              <p>
                {{ adsDetail.description }}
              </p>
            </div>
            <div class="bok-next-prev margin-t-40 margin-b-40">
              <a :href="adsDetail.link" class="next">
                <div class="icon">
                  <i class="ri-arrow-right-line"></i>
                </div>
                <span>Go To Link</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AdsInformation",
  props: ["id"],
  data() {
    return {
      ids: null,
      adsDetail: [],
    };
  },
  mounted() {
    this.getAdsDetail();
  },
  methods: {
    async getAdsDetail() {
      let ids = this.$props.id;
      try {
        await axios
          .get(process.env.VUE_APP_API + "api/ads/" + ids)
          .then((response) => (this.adsDetail = response.data.data));
      } catch (e) {
        console.log("Caught Error");
      }
    },
  },
};
</script>

<style scoped>
.un-details-blog .head .cover_img img.big {
  height: 400px;
}
</style>
