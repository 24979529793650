var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"un-details-blog d-lg-none d-block"},[_c('div',{staticClass:"head"},[_c('div',{staticClass:"cover_img"},[_c('picture',[_c('source',{staticClass:"img-fluid",staticStyle:{"width":"100% !important","height":"180px !important"},attrs:{"srcset":!_vm.adsDetail.photo?.startsWith('/images')
                ? 'https://api-dapps.dinowallet.org/images/ads/' +
                  _vm.adsDetail?.photo
                : _vm.adsDetail?.photo,"width":"100%","height":"180px","type":"image/webp"}}),_c('img',{staticClass:"img-fluid",staticStyle:{"width":"100% !important","height":"180px !important"},attrs:{"src":!_vm.adsDetail.photo?.startsWith('/images')
                ? 'https://api-dapps.dinowallet.org/images/ads/' +
                  _vm.adsDetail?.photo
                : _vm.adsDetail?.photo,"width":"100%","height":"180px","alt":""}})]),_c('div',{staticClass:"btn-xs-size bg-dark text-white rounded-pill position-absolute start-0 top-0 m-3"},[_vm._v(" "+_vm._s(_vm.adsDetail.name)+" ")])])]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"title-blog"},[_c('div',{staticClass:"others"},[_c('div',{staticClass:"time"},[_c('i',{staticClass:"ri-time-line"}),_c('span',[_vm._v(_vm._s(_vm.adsDetail.created_date))])])])]),_c('div',{staticClass:"description"},[_c('h2',[_vm._v(_vm._s(_vm.adsDetail.title))]),_c('p',[_vm._v(" "+_vm._s(_vm.adsDetail.description)+" ")])]),_c('div',{staticClass:"bok-next-prev margin-t-40 margin-b-40"},[_c('a',{staticClass:"next",attrs:{"href":_vm.adsDetail.link}},[_vm._m(0),_c('span',{staticClass:"text-success"},[_vm._v("Go To Link")])])])])]),_c('section',{staticClass:"bg-light d-lg-block d-none"},[_c('div',{staticClass:"container py-4",staticStyle:{"padding-left":"20px","padding-right":"20px"}},[_c('div',{staticClass:"bg-white p-4 rounded-10 un-details-blog"},[_c('div',{staticClass:"head mt-3"},[_c('div',{staticClass:"cover_img"},[_c('picture',[_c('source',{attrs:{"srcset":_vm.adsDetail.photo,"type":"image/webp"}}),_c('img',{staticClass:"big",attrs:{"src":_vm.adsDetail.photo,"alt":""}})]),_c('div',{staticClass:"btn-xs-size bg-dark text-white rounded-pill position-absolute start-0 top-0 m-3"},[_vm._v(" "+_vm._s(_vm.adsDetail.name)+" ")])])]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"title-blog"},[_c('div',{staticClass:"others"},[_c('div',{staticClass:"time"},[_c('i',{staticClass:"ri-time-line"}),_c('span',[_vm._v(_vm._s(_vm.adsDetail.created_date))])])])]),_c('div',{staticClass:"description"},[_c('h2',[_vm._v(_vm._s(_vm.adsDetail.title))]),_c('p',[_vm._v(" "+_vm._s(_vm.adsDetail.description)+" ")])]),_c('div',{staticClass:"bok-next-prev margin-t-40 margin-b-40"},[_c('a',{staticClass:"next",attrs:{"href":_vm.adsDetail.link}},[_vm._m(1),_c('span',[_vm._v("Go To Link")])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"ri-arrow-right-line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"ri-arrow-right-line"})])
}]

export { render, staticRenderFns }