<template>
  <div class="container px-lg-4 px-0">
    <header class="default heade-sticky">
      <div class="un-title-page go-back">
        <a href="#" @click="$router.go(-1)" class="icon">
          <i class="ri-arrow-drop-left-line"></i>
        </a>
      </div>
    </header>
    <div class="space-sticky"></div>
  </div>
</template>

<script>
export default {
  name: "HeaderBack",
};
</script>

<style>
</style>