<template>
  <div>
    <HeaderBack />
    <AdsInformation :id="$route.params.id" />
  </div>
</template>

<script>
import HeaderBack from "@/components/HeaderBack.vue";
import AdsInformation from "@/components/AdsInformation.vue";

export default {
  name: "DappsDetail",
  components: {
    HeaderBack,
    AdsInformation,
  },
  created() {
    this.$mixpanel.track("Visit Ads Detail " + this.$route.params.id);
  },
};
</script>

<style>
</style>